import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {CreateComponent} from './create/create.component'
import { CanActivateAuthGuard } from './can-activate-auth-guard';
import {LoginComponent} from './login/login.component';
import {EditComponent} from './edit/edit.component';
import {SearchComponent} from './search/search.component';
import {PendingItemsComponent} from './pending-items/pending-items.component';
import {HistoryComponent} from './history/history.component';
import {CompanyComponent} from './company/company.component';
import {NpfpComponent} from './npfp/npfp.component';
import {NpfpEditComponent} from './npfp-edit/npfp-edit.component';
import {PendingItemEditComponent} from './pending-item-edit/pending-item-edit.component';

const appRoutes: Routes = [
  { path: 'master-table', component: DashboardComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'create', component: CreateComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'pending-items', component: PendingItemsComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'history', component: HistoryComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'search', component: SearchComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'npfp/:id', component: NpfpComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'npfp-edit/:id', component: NpfpEditComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'pending-item-edit/:id', component: PendingItemEditComponent, canActivate: [CanActivateAuthGuard]},
  { path: 'edit/:id', component: EditComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'company/:id', component: CompanyComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/search', pathMatch: 'full'},
  { path: '**', redirectTo: '/search'}
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
