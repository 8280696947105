import {Component, Inject, OnInit} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../shared/user.service';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  private dropdownListCountries: any;
  private dropdownListLanguages: any;
  private dropdownListRegions: any;
  private selectedItems = [{}];
  private dropdownSettings = {};
  private $countries = []
  private $languages = []
  private $regions = []
  private selectedCountries = []
  private selectedLanguages = []
  private selectedRegions = []
  private noNewRequests = false
  private noPhysicalResources = false
  private theFormGroup: FormGroup
  private countryCount = 1
  private regionCount = 1
  private languageCount = 1

  constructor(
    private db: AngularFireDatabase,
    private router: Router,
    private builder: FormBuilder,
    public userService: UserService
  ) {
    this.theFormGroup = this.builder.group({
      nameCheck: ['', []],
      projectManagersCheck: ['', []],
      capacityCheck: ['', []],
      priorityCheck: ['', []],
      countriesCheck: ['', []],
      rrCheck: ['', []],
      ticketsCheck: ['', []],
    });  }

  ngOnInit() {
    this.populateLists()
  }

  submit(): void {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValidators(Validators.required);
      nameCheck.updateValueAndValidity();
    }
    const ticketsCheck = this.theFormGroup.get('ticketsCheck');
    if (ticketsCheck) {
      ticketsCheck.setValidators(Validators.required);
      ticketsCheck.updateValueAndValidity();
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValidators(Validators.required);
      priorityCheck.updateValueAndValidity();
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck){
      projectManagersCheck.setValidators(Validators.required);
      projectManagersCheck.updateValueAndValidity();
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck){
      capacityCheck.setValidators(Validators.required);
      capacityCheck.updateValueAndValidity();
    }
    const rr = this.theFormGroup.get('rrCheck');
    if (rr) {
      rr.setValidators(Validators.required);
      rr.updateValueAndValidity();
    }
    this.countryCount = this.selectedCountries.length
    this.regionCount = this.selectedRegions.length
    this.languageCount = this.selectedLanguages.length


    if (this.theFormGroup.valid && this.countryCount > 0 && this.regionCount > 0 && this.languageCount > 0) {
      this.sendToDataBase();
    } else {
    }

  }

  sendToDataBase(): void {
    // company creation needs to be tied to account creation to tie together the node title and uid
    const name = (document.getElementById('name') as HTMLInputElement).value
    const projectManagers = Number((document.getElementById('projectManagers') as HTMLInputElement).value)
    const capacity = Number((document.getElementById('capacity') as HTMLInputElement).value)
    const priority = Number((document.getElementById('priority') as HTMLInputElement).value)
    const tickets = Number((document.getElementById('tickets') as HTMLInputElement).value)
    const rr = Number((document.getElementById('rr') as HTMLInputElement).value)
    const countryArray: String[] = []
    for(const element in this.selectedCountries) {
      countryArray.push(this.selectedCountries[element]['item_text']);
    }
    const regionArray: String[] = []
    for(const element in this.selectedRegions) {
      regionArray.push(this.selectedRegions[element]['item_text']);
    }
    const languagesArray: String[] = []
    for(const element in this.selectedLanguages) {
      languagesArray.push(this.selectedLanguages[element]['item_text']);
    }
    const covidStatus = {
      'noNewRequests': this.noNewRequests, 'noPhysicalResources': this.noPhysicalResources
    }
    const newId = this.db.createPushId();
    // newId should be user Id
    const ticketData = {'name': name, 'tickets': tickets, 'newId': newId}
    const npfp = {'name': name, 'capacity': Number(capacity), 'countries': countryArray, 'regions': regionArray,
      'languages': languagesArray, 'newId': newId, 'projectManagers': Number(projectManagers),
      'covidStatus': covidStatus }
    const company = {'name': name, 'capacity': Number(capacity), 'priority': priority,
      'countries': countryArray, 'regions': regionArray, 'projectManagers': Number(projectManagers),
      'languages': languagesArray, 'newId': newId, 'rr': rr, 'status': true, 'covidStatus': covidStatus };
    this.db.object('/NPFP/Companies/' + newId).update(company)
      .then(() => {
        this.db.object('NPFP/NPFP/' + newId).update(npfp).then(() => {
          this.db.object('/NPFP/Tickets/' + newId).update(ticketData).then(() =>
            this.router.navigate(['/master-table'])
          )
        })
      })
  }


  populateLists(): void {
    this.db.object('/NPFP/Variables/Countries').query.once('value')
      .then(snapshot => {
        this.$countries = snapshot.val();
        this.dropdownListCountries = [];
        for (let i = 1; i < this.$countries.length; i ++) {
          this.dropdownListCountries.push({item_id: i, item_text: this.$countries[i]});
        }
      });
    this.db.object('/NPFP/Variables/Languages').query.once('value')
      .then(snapshot => {
        this.$languages = snapshot.val();
        this.dropdownListLanguages = [];
        for (let i = 0; i < this.$languages.length; i ++) {
          this.dropdownListLanguages.push({item_id: i, item_text: this.$languages[i]});
        }
      });
    this.db.object('/NPFP/Variables/Regions').query.once('value')
      .then(snapshot => {
        this.$regions = snapshot.val();
        this.dropdownListRegions = [];
        for (let i = 0; i < this.$regions.length; i ++) {
          this.dropdownListRegions.push({item_id: i, item_text: this.$regions[i]});
        }
      });
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create.component.dialog.html',
})

export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
