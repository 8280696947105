import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from './shared/auth.service';
import {UserService} from './shared/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  private isAlive = true;
  public power = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private userService: UserService
  ) {

  }

  ngAfterViewInit() {
    this.authService.afAuth.user.subscribe(ref => {
      if (ref) {
        ref.getIdTokenResult(true).then(ref1 => this.power = ref1.claims.admin);
      }})
    this.userService.checkAuth().then( () => {
      this.nav();
    })
  }
  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.router.navigate(['login'])
        this.power = false;
      }, (error) => {
        console.log('Logout error', error);
      });
  }
  ngOnDestroy(): void {
    this.isAlive = false;
  }

  nav(): void {
    if (this.userService.afAuth.auth.currentUser) {
      if (!this.userService.admin) {
        if (this.userService.npfpId) {
          this.router.navigate(['/npfp/' + this.userService.npfpId])
        } else if (((/@netflix.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
          this.userService.afAuth.auth.currentUser.email : '') ||
          (/@netflixcontractors.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
            this.userService.afAuth.auth.currentUser.email : '')))) && !this.userService.admin && this.userService.afAuth.auth.currentUser.emailVerified) {
          this.router.navigate(['/search'])
        } else {
          this.router.navigate(['/login'])
        }
      }
    }
  }
}
