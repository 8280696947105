import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../shared/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../shared/user.service';


@Component({
  selector: 'app-edit',
  templateUrl: './npfp-edit.component.html',
  styleUrls: ['./npfp-edit.component.scss']
})
export class NpfpEditComponent implements OnInit {
  public $company!: Company;
  public id!: string;
  public priority: number[] = [1, 2, 3];
  dropdownListCountries: any;
  dropdownListLanguages: any;
  dropdownListRegions: any;
  theFormGroup: FormGroup
  selectedRegions: any;
  selectedCountries: any;
  selectedLanguages: any;
  noNewRequests: any;
  noPhysicalResources: any;
  countryCount = 1
  regionCount = 1
  languageCount = 1
  dropdownSettings = {};
  public $countries: String[] = []
  public $messages: any
  public $languages: String[] = []
  public $regions: String[] = []
  public $submissionSuccessful = false
  constructor(
    private db: AngularFireDatabase,
    private route: ActivatedRoute,
    public router: Router,
    private builder: FormBuilder,
    public userService: UserService
  ) {    this.theFormGroup = this.builder.group({
    nameCheck: ['', []],
    capacityCheck: ['', []],
    projectManagersCheck: ['', []],
    priorityCheck: ['', []],
    countriesCheck: ['', []]
  }); }

  ngOnInit() {
    this.populateDropdown()
    this.setInitialValidators()
    this.nav()
  }
  setInitialValidators() {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValue(false)
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValue(false)
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValue(false)
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValue(false)
    }
  }
  submit(): void {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValidators(Validators.required);
      nameCheck.updateValueAndValidity();
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValidators(Validators.required);
      priorityCheck.updateValueAndValidity();
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValidators(Validators.required);
      projectManagersCheck.updateValueAndValidity();
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValidators(Validators.required);
      capacityCheck.updateValueAndValidity();
    }
    if (this.selectedCountries != null) {
      this.countryCount = this.selectedCountries.length
    } else {
      this.countryCount = 0;
    }
    if (this.selectedRegions != null) {
      this.regionCount = this.selectedRegions.length
    } else {
      this.regionCount = 0;
    }
    if (this.selectedLanguages != null) {
      this.languageCount = this.selectedLanguages.length
    } else {
      this.languageCount = 0;
    }
    if (this.theFormGroup.valid && this.countryCount > 0 && this.languageCount > 0) {
      this.sendToDataBase();
    } else {
    }
  }
  getCompanyInfo(): void {
    const check = this.route.snapshot.paramMap.get('id')
    this.id = check !== null ? check : '';
    this.db.object('/NPFP/NPFP/' + this.id).query.once('value')
      .then(snapshot => {
        this.$company = snapshot.val()
        const countries = snapshot.val().countries.sort();
        const languages = snapshot.val().languages.sort();
        const covidStatus = snapshot.val().covidStatus
        if (countries != null) {
          for (let i = 0; i < countries.length; i++) {
            if (this.selectedCountries == null) {
              this.selectedCountries = ([{item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]}])
            } else {
              this.selectedCountries.push({item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]})
            }
          }
        }
        if (languages != null) {
          for (let i = 0; i < languages.length; i++) {
            if (this.selectedLanguages == null) {
              this.selectedLanguages = ([{item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]}]);
            } else {
              this.selectedLanguages.push({item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]});
            }
          }
        }
        if(covidStatus != null) {
          this.noNewRequests = covidStatus.noNewRequests
          this.noPhysicalResources = covidStatus.noPhysicalResources
        }
        else {
          this.noNewRequests = false
          this.noPhysicalResources = false
        }
      });
  }
  nav(): void {
    if (this.userService.afAuth.auth.currentUser) {
      if (!this.userService.npfpId) {
        if (((/@netflix.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
          this.userService.afAuth.auth.currentUser.email : '') ||
          (/@netflixcontractors.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
            this.userService.afAuth.auth.currentUser.email : '')))) && !this.userService.admin) {
          this.router.navigate(['/search'])
        } else if (this.userService.npfpId) {
          this.router.navigate(['/login'])
        }
      }
    }
  }
  sendToDataBase(): void {
    const today = new Date();
    const date = today.getFullYear() + '-' + (('0' + (today.getMonth() + 1)).slice(-2)) + '-' + ('0' + today.getDate()).slice(-2);
    const id = this.route.snapshot.paramMap.get('id');
    // company creation needs to be tied to account creation to tie together the node title and uid
    const projectManagers = Number((document.getElementById('projectManagers') as HTMLInputElement).value)
    const capacity = Number((document.getElementById('capacity') as HTMLInputElement).value)
    let regionArray: String[] = []
    const countryArray: String[] = []
    for (const element in this.selectedCountries) {
      countryArray.push(this.selectedCountries[element]['item_text']);
      regionArray.push(this.$regions[this.$countries.indexOf(this.selectedCountries[element]['item_text'])])
    }
    const languagesArray: String[] = []
    for (const element in this.selectedLanguages) {
      languagesArray.push(this.selectedLanguages[element]['item_text']);
    }
    regionArray = this.duplicateRemoval(regionArray)
    const pendingId = this.db.createPushId()
    // newId should be user Id
    let email!: any;
    const current_user = this.userService.afAuth.auth.currentUser;
    if (current_user) {
      email = current_user.email;
    }

    const company: {[id: string]: any} = {'id': id, 'subId': pendingId, 'date': date, 'currentName': this.$company.name,
      'email': email};
  
    const messages: String[] = []
    if (this.$company.projectManagers !== projectManagers) {
      messages.push('Changed the number of NPFP Project Managers from ' + String(this.$company.projectManagers) + ' to ' + String(projectManagers))
      company['projectManagers'] = projectManagers;
    }
    if (this.$company.capacity !== capacity) {
      messages.push('Changed capacity from ' + String(this.$company.capacity) + ' to ' + String(capacity))
      company['capacity'] = capacity;
    }
    if (!this.arrayEquality( this.$company.countries, countryArray)) {
      const countriesRemoved = this.$company.countries.filter(x => !countryArray.includes(x));
      if (countriesRemoved.length !== 0) {
        messages.push('Removed the following countries: ' + countriesRemoved.join(', '))
      }
      const countriesAdded = countryArray.filter(x => !this.$company.countries.includes(x));
      if (countriesAdded.length !== 0) {
        messages.push('Added the following countries: ' + countriesAdded.join(', '))
      }
      company['countries'] = countryArray;
      company['regions'] = regionArray;
    }
    if (!this.arrayEquality( this.$company.languages, languagesArray)) {
      const languagesRemoved = this.$company.languages.filter(x => !languagesArray.includes(x));
      if (languagesRemoved.length !== 0) {
        messages.push('Removed the following languages: ' + languagesRemoved.join(', '))
      }
      const languagesAdded = languagesArray.filter(x => !this.$company.languages.includes(x));
      if (languagesAdded.length !== 0) {
        messages.push('Added the following languages: ' + languagesAdded.join(', '))
      }
      company['languages'] = languagesArray;
    }
 
    let covidStatuses:any = {}
    if (this.noNewRequests != null)
      covidStatuses.noNewRequests = this.noNewRequests
    if (this.noPhysicalResources != null)
      covidStatuses.noPhysicalResources = this.noPhysicalResources
    if (!this.$company.covidStatus) {
      if (covidStatuses.noNewRequests)
        messages.push('Changed the COVID-19 status from undefined to ' + String(covidStatuses.noNewRequests ? 'not ': '') + 'accepting new projects')
      if (covidStatuses.noPhysicalResources)
        messages.push('Changed the COVID-19 status from undefined to ' + String(covidStatuses.noNewRequests ? 'not ': '') + 'accepting physical source materials') 
      company['covidStatus'] = covidStatuses;
    }
    else if (JSON.stringify(this.$company.covidStatus) !== JSON.stringify(covidStatuses)) {
      if(this.$company.covidStatus.noNewRequests !== covidStatuses.noNewRequests) {
        messages.push('Changed the COVID-19 status from ' + String(this.$company.covidStatus.noNewRequests ? 'not ': '') + 'accepting new projects to ' + String(covidStatuses.noNewRequests ? 'not ': '') + 'accepting new projects')
      }
      if(this.$company.covidStatus.noPhysicalResources !== covidStatuses.noPhysicalResources) {
        messages.push('Changed the COVID-19 status from ' + String(this.$company.covidStatus.noPhysicalResources ? 'not ': '') + 'accepting physical source materials to ' + String(covidStatuses.noPhysicalResources ? 'not ': '') + 'accepting physical source materials')
      }
      company['covidStatus'] = covidStatuses;
    }

    if (messages.length !== 0) {
      company['messages'] = messages
      this.db.object('/NPFP/Submitted/' + pendingId).update(company)
        .then(() => {
          // this.router.navigate(['/npfp/' + id])
          this.$submissionSuccessful = true
          this.$messages = messages
        })
    } else {
      this.router.navigate(['/npfp/' + id])
    }
  }
  arrayEquality(arrayOne: String[], arrayTwo: String[]): boolean {
    if (arrayOne === arrayTwo) {
      return true}
    if (arrayOne == null || arrayTwo == null) {return false};
    if (arrayOne.length !== arrayTwo.length) {return false};

    arrayOne = arrayOne.sort()
    arrayTwo = arrayTwo.sort()

    for (let i = 0; i < arrayOne.length; ++ i) {
      if (arrayOne[i] !== arrayTwo[i]) {return false}
    }
    return true
  }

  duplicateRemoval = function(a: any) {
    let seen!: any;
    seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1 && item !== undefined ) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }
  goBack(): void {
    this.router.navigate(['/npfp/' + this.id])
  }
  populateDropdown(): void {

    this.db.object('/NPFP/NPFPV/Countries').query.once('value')
      .then(snapshot => {
        this.$countries = snapshot.val();
        this.dropdownListCountries = [];
        for (let i = 1; i < this.$countries.length; i ++) {
          this.dropdownListCountries.push({item_id: i, item_text: this.$countries[i]});
        }
      }).then(() => {this.db.object('/NPFP/NPFPV/Languages').query.once('value')
      .then(snapshot => {
        this.$languages = snapshot.val();
        this.dropdownListLanguages = [];
        for (let i = 0; i < this.$languages.length; i ++) {
          this.dropdownListLanguages.push({item_id: i, item_text: this.$languages[i]});
        }
      })}).then(() => {
      this.db.object('/NPFP/Mapping/countryRegionMap').query.once('value')
        .then(snapshot => {
          this.$regions = snapshot.val();
        }).then(() => this.getCompanyInfo())
    })



    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


}
