import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../shared/models';
import {UserService} from '../shared/user.service';

@Component({
  selector: 'app-company',
  templateUrl: './npfp.component.html',
  styleUrls: ['./npfp.component.scss']
})
export class NpfpComponent implements OnInit {
  public $company!: Company;
  public id!: string;
  constructor(
    private db: AngularFireDatabase,
    private route: ActivatedRoute,
    public router: Router,
    public userService: UserService
  ) { }

  ngOnInit() {
    this.getCompanyInfo()
    this.nav()
  }
  getCompanyInfo(): void {
    const check = this.route.snapshot.paramMap.get('id')
    this.id = check !== null ? check : '';
    this.db.object('/NPFP/NPFP/' + this.id).query.once('value')
      .then(snapshot => {
        const companyObject = snapshot.val()
        companyObject['languages'] = companyObject['languages'].sort();
        companyObject['countries'] = companyObject['countries'].sort();
        if(companyObject['covidStatus'] != null) {
          let covidArray = []
          if(companyObject['covidStatus']['noNewRequests'] === true)
              covidArray.push("- Cannot accept new projects")
          if(companyObject['covidStatus']['noPhysicalResources'] === true)
              covidArray.push("- Unable to accept physical source materials")
          companyObject['covidStatus'] = covidArray.join("\n")
        }
        this.$company = companyObject
      });
  }
  nav(): void {
    if (this.userService.afAuth.auth.currentUser) {
      if (!this.userService.npfpId) {
        if (((/@netflix.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
          this.userService.afAuth.auth.currentUser.email : '') ||
          (/@netflixcontractors.com\s*$/.test(this.userService.afAuth.auth.currentUser.email ?
            this.userService.afAuth.auth.currentUser.email : '')))) && !this.userService.admin) {
          this.router.navigate(['/search'])
        } else if (this.userService.npfpId) {
          this.router.navigate(['/login'])
        }
      }
    }
  }


}
