import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
// This import is for the dialog popup
// import {DialogComponent} from '../create/create.component';
import {Company} from '../shared/models';
import {UserService} from '../shared/user.service';
import {Router} from '@angular/router';
import {MatTooltipModule} from '@angular/material/tooltip';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  private $countries!: String[];
  private email!: any;
  private $selectedCompany!: string[][];
  private $selectedCountries = [];
  private $typeOfMatchArray!: string[]
  private buttonText = 'add';
  private $selectedLanguage = [{item_id: 10, item_text: 'English'}];
  private $languages!: String[];
  dropdownListCountries: any;
  dropdownListLanguages: any;
  dropdownSettings = {};
  constructor(    private afAuth: AngularFireAuth,
                  private db: AngularFireDatabase,
                  public userService: UserService,
                  private router: Router ) { }
  ngOnInit() {
    this.db.object('/NPFP/Variables/Countries').query.once('value')
      .then(snapshot => {
        this.$countries = snapshot.val();
        this.dropdownListCountries = [];
        for (let i = 1; i < this.$countries.length; i ++) {
          this.dropdownListCountries.push({item_id: i, item_text: this.$countries[i]});
        }
      }).then(() => {
      this.db.object('NPFP/Variables/Languages').query.once('value')
        .then(snapshot => {
          this.$languages = snapshot.val();
          this.dropdownListLanguages = [];
          for (let i = 0; i < this.$languages.length; i ++) {
            this.dropdownListLanguages.push({item_id: i, item_text: this.$languages[i]});
          }
        })
    });
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      allowSearchFilter: true,
    };
  }
  submit(): void {
    let  countrySelected = ''
    if (this.$selectedCountries.length !== 0) {
      countrySelected = this.$selectedCountries[0]['item_text'];
    }
    let languageSelected = '';
    if (this.$selectedLanguage.length !== 0) {
      languageSelected = this.$selectedLanguage[0]['item_text'];
    }
    const regionNumber = this.$countries.indexOf(countrySelected)
    if (this.$selectedCountries.length !== 0 && this.$selectedLanguage.length !== 0) {
      this.db.object('/NPFP/Mapping/countryRegionMap/' + String(regionNumber)).query.once('value').then(snapshot => {
        this.searchCountryRegionLang(countrySelected, languageSelected, snapshot.val());
      })
    } else if (this.$selectedLanguage.length !== 0) {
      this.searchCountryRegionLang('', languageSelected, '');
    }
    // JiYoung requested that Country and language not be removed when the submit button is clicked.
    // this.$selectedCountries = [];
    // this.$selectedLanguage = [{item_id: 10, item_text: 'English'}];
    this.$typeOfMatchArray = []
  }

  searchCountryRegionLang(country: string, language: string, region: string): void {
    this.$selectedCompany = [];
    this.db.list('/NPFP/Tickets').query.once('value').then( tickets => {
      this.db.list('/NPFP/Companies').query.once( 'value').then(ref => {
        const sameCountryFirst = []
        const sameCountrySecond = []
        const sameCountryThird = []
        const sameRegionFirst = []
        const sameRegionSecond = []
        const sameRegionThird = []
        const sameLanguageFirst = []
        const sameLanguageSecond = []
        const sameLanguageThird = []
        let combinedRegionsSortedByReturnRates: any[] = []
        let combinedCountriesSortedByReturnRates: any[] = []
        let combinedLanguagesSortedByReturnRates: any[] = []
        for (const company in ref.val() ) {
          let reference: any = {};
          let locationOfCountry = -1;
          let locationOfRegion = -1;
          let locationOfLanguage = -1;
          let remainingCap = 0;
          reference = ref.val()[company]
          const countryArray = reference['countries'];
          const languageArray = reference['languages'];
          const regionArray = reference['regions'];
          const status = reference['status'];
          const covidStatus = reference['covidStatus']
          let noNewRequests = false;
          let noPhysicalResources = false;
          let physicalResourceStatus: string;
          let rr = reference['rr'];
          let onTime = reference['onTime'];
          const url = reference['url'];
          if (covidStatus != null) {
            noNewRequests = covidStatus.noNewRequests
            noPhysicalResources = covidStatus.noPhysicalResources
          }
          if (noPhysicalResources) {
            physicalResourceStatus = 'Unable to accept physical source materials'
          }
          else {
            physicalResourceStatus = ''
          }
          if (rr == null) {
            rr = 0;
          }
          if (onTime == null) {
            onTime = 0;
          }
          if (countryArray != null) {
            locationOfCountry = countryArray.indexOf(country)
          }
          if (languageArray != null) {
            locationOfLanguage = languageArray.indexOf(language)
          }
          if (regionArray != null) {
            locationOfRegion = regionArray.indexOf(region)
          }
          const numberOfCapacity = reference['capacity']
          const newId = reference['newId']
          const numberOfTickets = tickets.val()[newId]['tickets']
          if (numberOfCapacity != null && numberOfTickets != null) {
            remainingCap = numberOfCapacity - numberOfTickets;
          }
          const priority = reference['priority'];
          if (locationOfCountry > -1 && status === true && locationOfLanguage > -1 && noNewRequests === false) {
            if (priority === 1 && rr < 5) {
              sameCountryFirst.push([reference['name'], rr, language, 'Country Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 2  && rr < 5) {
              sameCountrySecond.push([reference['name'],  rr, language, 'Country Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 3  && rr < 5) {
              sameCountryThird.push([reference['name'],  rr, language, 'Country Match', onTime, url, countryArray, physicalResourceStatus])
            }
          }
          if (locationOfRegion > -1 && status === true && locationOfLanguage > -1 && noNewRequests === false) {
            if (priority === 1  && rr < 5) {
              sameRegionFirst.push([reference['name'],  rr, language, 'Region Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 2 && rr < 5) {
              sameRegionSecond.push([reference['name'],  rr, language, 'Region Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 3  && rr < 5)  {
              sameRegionThird.push([reference['name'],  rr, language, 'Region Match', onTime, url, countryArray, physicalResourceStatus])
            }
          }
          if (status === true && locationOfLanguage > -1 && noNewRequests === false) {
            if (priority === 1  && rr < 5) {
              sameLanguageFirst.push([reference['name'],  rr, language, 'Language Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 2 && rr < 5) {
              sameLanguageSecond.push([reference['name'],  rr, language, 'Language Match', onTime, url, countryArray, physicalResourceStatus])
            } else if (priority === 3  && rr < 5)  {
              sameLanguageThird.push([reference['name'],  rr, language, 'Language Match', onTime, url, countryArray, physicalResourceStatus])
            }
          }
        }
        // At this point we've filtered by country and region and made sure their remaining capacity is greater than 0
        if (sameCountryFirst.length !== 0 ) {
          // this.getCompanyName(sameCountryFirst[0], country)
          if (combinedCountriesSortedByReturnRates.length === 0) {
            combinedCountriesSortedByReturnRates = sameCountryFirst.sort(this.Comparator);
          } else {
            combinedCountriesSortedByReturnRates = combinedCountriesSortedByReturnRates.concat(sameCountryFirst.sort(this.Comparator));
          }
        }
        if (sameCountrySecond.length !== 0 ) {
          // this.getCompanyName(sameCountrySecond[0], country)
          if (combinedCountriesSortedByReturnRates.length === 0) {
            combinedCountriesSortedByReturnRates = sameCountrySecond.sort(this.Comparator);
          } else {
            combinedCountriesSortedByReturnRates = combinedCountriesSortedByReturnRates.concat(sameCountrySecond.sort(this.Comparator));
          }
        }
        if (sameCountryThird.length !== 0) {
          // this.getCompanyName(sameCountryThird[0], country)
          if (combinedCountriesSortedByReturnRates.length === 0) {
            combinedCountriesSortedByReturnRates = sameCountryThird.sort(this.Comparator);
          } else {
            combinedCountriesSortedByReturnRates = combinedCountriesSortedByReturnRates.concat(sameCountryThird.sort(this.Comparator));
          }
        }
        if (sameRegionFirst.length !== 0 ) {
          // this.getCompanyName(sameRegionFirst[0], country)
          if (combinedRegionsSortedByReturnRates.length === 0) {
            combinedRegionsSortedByReturnRates = sameRegionFirst.sort(this.Comparator);
          } else {
            combinedRegionsSortedByReturnRates = combinedRegionsSortedByReturnRates.concat(sameRegionFirst.sort(this.Comparator));
          }
        }
        if (sameRegionSecond.length !== 0 ) {
          // this.getCompanyName(sameRegionSecond[0], country)
          if (combinedRegionsSortedByReturnRates.length === 0) {
            combinedRegionsSortedByReturnRates = sameRegionSecond.sort(this.Comparator);
          } else {
            combinedRegionsSortedByReturnRates = combinedRegionsSortedByReturnRates.concat(sameRegionSecond.sort(this.Comparator));
          }
        }
        if (sameRegionThird.length !== 0 ) {
          // this.getCompanyName(sameRegionThird[0], country)
          if (combinedRegionsSortedByReturnRates.length === 0) {
            combinedRegionsSortedByReturnRates = sameRegionThird.sort(this.Comparator);
          } else {
            combinedRegionsSortedByReturnRates = combinedRegionsSortedByReturnRates.concat(sameRegionThird.sort(this.Comparator));
          }
        }
        if (sameLanguageFirst.length !== 0 ) {
          // this.getCompanyName(sameRegionFirst[0], country)
          if (combinedLanguagesSortedByReturnRates.length === 0) {
            combinedLanguagesSortedByReturnRates = sameLanguageFirst.sort(this.Comparator);
          } else {
            combinedLanguagesSortedByReturnRates = combinedLanguagesSortedByReturnRates.concat(sameLanguageFirst.sort(this.Comparator));
          }
        }
        if (sameLanguageSecond.length !== 0 ) {
          // this.getCompanyName(sameRegionSecond[0], country)
          if (combinedLanguagesSortedByReturnRates.length === 0) {
            combinedLanguagesSortedByReturnRates = sameLanguageSecond.sort(this.Comparator);
          } else {
            combinedLanguagesSortedByReturnRates = combinedLanguagesSortedByReturnRates.concat(sameLanguageSecond.sort(this.Comparator));
          }
        }
        if (sameLanguageThird.length !== 0 ) {
          // this.getCompanyName(sameRegionThird[0], country)
          if (combinedLanguagesSortedByReturnRates.length === 0) {
            combinedLanguagesSortedByReturnRates = sameLanguageThird.sort(this.Comparator);
          } else {
            combinedLanguagesSortedByReturnRates = combinedLanguagesSortedByReturnRates.concat(sameLanguageThird.sort(this.Comparator));
          }
        }
        if (combinedRegionsSortedByReturnRates.length === 0 && combinedCountriesSortedByReturnRates.length === 0 && combinedLanguagesSortedByReturnRates.length === 0) {
          this.$selectedCompany = [['There are no NPFP matches based on the criteria of your search. Please reach out to TPO (tpo@netflix.com) for further assistance.', 'Issue', '0']]
          this.updateSearchWithEmptyCompany(country, language)
        } else {
          const countryAndRegions = this.duplicateRemoval(combinedCountriesSortedByReturnRates.concat(combinedRegionsSortedByReturnRates).concat(combinedLanguagesSortedByReturnRates))
          if (countryAndRegions.length >= 3) {
            this.$selectedCompany = countryAndRegions.slice(0, 3)
            this.updateSearchHistory(this.$selectedCompany, country, language)
          } else {
            this.$selectedCompany = countryAndRegions
            this.updateSearchHistory(this.$selectedCompany, country, language)
          }
        }
      })
    })
  }
  Comparator = function(a: String[], b: String[]) {
    if (a[1] < b[1]) {
      return -1}
    if (a[1] > b[1]) {
      return 1}
    if (a[4] > b[4]) {
      return -1}
    if (a[4] < b[4]) {
      return 1}
    return 0;
  }
  duplicateRemoval = function(a: any) {
    const seen: {[id: string]: any} = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i].slice(0, 3).join('| ');
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[ j ++] = a[i];
      }
    }
    return out;
  }
  updateSearchHistory(companies: String[][], country: String, language: String): void {
    const companyArray = []
    const matchArray = []
    country = country || 'No country was selected';
    for (const company in companies ) {
      companyArray.push(companies[company][0])
      matchArray.push(companies[company][3])
    }
    const today = new Date();
    const date = today.getFullYear() + '-' + (('0' + (today.getMonth() + 1)).slice(-2)) + '-' + ('0' + today.getDate()).slice(-2);
    const step1 = this.afAuth.auth.currentUser;
    if (step1 != null) {
      this.email = step1.email
    }
    const object = {'country': country, 'company': companyArray, 'language': language, 'matchType': matchArray, 'date': date, 'email': this.email};
    this.db.list('/NPFP/SearchResults/').push(object);
  }

  updateSearchWithEmptyCompany(country: any, language: String): void {
    country = country || 'No country was selected';
    const today = new Date();
    const date = today.getFullYear() + '-' + (('0' + (today.getMonth() + 1)).slice(-2)) + '-' + ('0' + today.getDate()).slice(-2);
    const step1 = this.afAuth.auth.currentUser;
    if (step1 != null) {
      this.email = step1.email
    }
    const object = {'country': country, 'company': 'No companies were returned', 'language': language, 'matchType': 'NA', 'date': date, 'email': this.email};
    if (country !== '') {
      this.db.list('/NPFP/SearchResults/').push(object);
    }
  }

  toggleLanguage(): void {
    const x = document.getElementById('langSearch');
    const y = document.getElementById('langText');
    if ( x !== null && y !== null) {
      if (x.style.display === 'none') {
        x.style.display = 'block';
        y.innerHTML = 'hide language choice';
        this.buttonText = 'remove';
      } else {
        x.style.display = 'none';
        y.innerHTML = 'Select project management language<br/> for content partner (optional)';
        this.buttonText = 'add';
      }
    }
  }

  goToLink(url: string){
    window.open(url, '_blank');
  }



}



