import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from '@angular/fire/auth';
import {Router} from '@angular/router';


@Injectable()
export class UserService {
  public power!: boolean;
  public npfpId!: string;
  public admin!: boolean;
  constructor(
    public afAuth: AngularFireAuth,
  ) {
  }
  getCurrentUser(router: Router) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.onAuthStateChanged(user => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
          router.navigate(['/login'])
        }
      }
      )
    })
  }
  checkAuth() {
    return new Promise<any>((resolve) => {
    this.afAuth.user.subscribe(auth => {
      if (auth) {
        auth.getIdTokenResult(true).then(ref1 => {
          this.npfpId = ref1.claims.npfpId;
          this.admin = ref1.claims.admin;
          resolve()
        })
      }
    })
  })
  }



}
