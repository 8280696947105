import { Pipe, PipeTransform } from '@angular/core';
import { Company } from './models';

@Pipe({
    name: 'filterbytitle'
})
export class FilterByTitlePipe implements PipeTransform {
    transform(filmStatuses: Array<Company>, filter: string): Array<Company> {
        if (filmStatuses) {
            const regexp = new RegExp(`.*${filter}.*`, 'i');
            return filmStatuses.filter(b => regexp.test(b.name));
        }
        return filmStatuses;
    }
}
