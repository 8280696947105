import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../shared/models';
import {UserService} from '../shared/user.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public $company!: Company;
  public $tickets: any;
  public newId!: string;
  constructor(
    private db: AngularFireDatabase,
    private route: ActivatedRoute,
    public router: Router,
    public userService: UserService
  ) { }
  ngOnInit() {
    this.getCompanyInfo()
  }
  getCompanyInfo(): void {
    const check = this.route.snapshot.paramMap.get('id')
    this.newId = check !== null ? check : '';
    this.newId = check !== null ? check : '';
    this.db.object('/NPFP/Tickets/' + this.newId).query.once('value').then(tickets => {
      this.$tickets = tickets.val()
      this.db.object('/NPFP/Companies/' + this.newId).query.once('value')
        .then(snapshot => {
          const companyObject = snapshot.val()
          companyObject['regions'] = companyObject['regions'].sort();
          companyObject['languages'] = companyObject['languages'].sort();
          companyObject['countries'] = companyObject['countries'].sort();

          if(companyObject['covidStatus'] != null) {
            let covidArray = []
            if(companyObject['covidStatus']['noNewRequests'] === true)
              covidArray.push("- Cannot accept new projects")
            if(companyObject['covidStatus']['noPhysicalResources'] === true)
              covidArray.push("- Unable to accept physical source materials")
            companyObject['covidStatus'] = covidArray.join("\n")
          }

          this.$company = companyObject
        });
    })

  }




}
