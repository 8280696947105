import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../shared/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public npfpId!: string;
  public admin!: boolean;
  public emailVerify!: boolean;
  private verificationSent!: boolean;
  constructor(public router: Router,
              public authService: AuthService) {}

  ngOnInit() {
     this.authService.afAuth.user.subscribe(auth => {
      if (auth) {
        auth.getIdTokenResult(true).then(ref1 => {
          this.npfpId = ref1.claims.npfpId;
          this.admin = ref1.claims.npfpId;
        }).then(() => {
            if (this.npfpId) {
              this.router.navigate(['/npfp/' + this.npfpId])
            } else {
              if (auth && ((/@netflix.com\s*$/.test(auth.email ? auth.email : '') ||
                (/@netflixcontractors.com\s*$/.test(auth.email ? auth.email : ''))))) {
                if (auth.emailVerified) {
                  if (this.admin) {
                    this.router.navigate(['/master-table'])
                  } else {
                    this.router.navigate(['/search'])
                  }
                } else {
                  this.emailVerify = true;
                  if (!this.verificationSent) {
                    this.emailVerify = true;
                    this.verificationSent = true;
                    auth.sendEmailVerification()
                  }
                }
              } else {
                this.emailVerify = false;
              }
            }
          }
        );
      } else {
        this.emailVerify = false;
      }
    })

  }
}
