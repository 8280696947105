import { FilterByTitlePipe } from './shared/filter-by-title.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database-deprecated';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatToolbarModule,
  MatChipsModule,
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatFormFieldModule,
  MatCardModule
} from '@angular/material';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing.module';
import { CanActivateAuthGuard } from './can-activate-auth-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyService } from './shared/film.service';
import { OrderByDatePipe } from './shared/order-by-date.pipe';
import { TrimTitlePipe } from './shared/trim-title.pipe';
import { HttpModule } from '@angular/http';
import { FilterByOwnerPipe } from 'app/shared/filter-by-owner-id.pipe';
import { PathLocationStrategy} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabase} from '@angular/fire/database';
import { LoginComponent } from './login/login.component';
import {AuthService} from './shared/auth.service';
import {UserService} from './shared/user.service';
import { CommonModule } from '@angular/common';
import { CreateComponent, DialogComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { SearchComponent } from './search/search.component';
import { PendingItemsComponent } from './pending-items/pending-items.component'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HistoryComponent } from './history/history.component';
import { CompanyComponent } from './company/company.component';
import { NpfpComponent } from './npfp/npfp.component';
import { NpfpEditComponent } from './npfp-edit/npfp-edit.component';
import { PendingItemEditComponent } from './pending-item-edit/pending-item-edit.component';
import { MatTableModule, MatProgressBarModule, MatSortModule, MatInputModule } from '@angular/material';



const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    }
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    OrderByDatePipe,
    FilterByTitlePipe,
    FilterByOwnerPipe,
    TrimTitlePipe,
    LoginComponent,
    CreateComponent,
    EditComponent,
    SearchComponent,
    PendingItemsComponent,
    DialogComponent,
    HistoryComponent,
    CompanyComponent,
    NpfpComponent,
    NpfpEditComponent,
    PendingItemEditComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase, 'NPFP'),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AngularFireDatabaseModule,
    ReactiveFormsModule,
    HttpModule,
    AngularFireAuthModule,
    HttpClientModule,
    BrowserModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    CommonModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatTableModule,
    MatProgressBarModule,
    MatSortModule,
    MatInputModule,
    MatCardModule
  ],
  providers: [
    AngularFireAuth,
    CompanyService,
    CanActivateAuthGuard,
    PathLocationStrategy,
    HttpClientModule,
    HttpModule,
    AngularFireDatabase,
    AuthService,
    UserService
  ],
  entryComponents: [
    DialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
