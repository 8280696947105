
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {UserService} from './shared/user.service';

@Injectable()
export class CanActivateAuthGuard implements CanActivate {
  constructor(    public userService: UserService,
                  private router: Router ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser(this.router)
        .then(user => {
          return resolve(true);
        }, err => {
          return resolve(false);
          console.log('Auth Gaurd Error')
        })
    })
  }
}
