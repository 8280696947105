import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireDatabase} from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../shared/user.service';

@Component({
  selector: 'app-pending-item-edit',
  templateUrl: './pending-item-edit.component.html',
  styleUrls: ['./pending-item-edit.component.scss']
})
export class PendingItemEditComponent implements OnInit {
  public id!: string;
  public name!: string;
  public date!: any;
  public projectManagers!: string;
  public capacity!: string;
  public covidStatus!: any;
  public userId!: string;
  public npfpEmail!: string;
  public priority: number[] = [1, 2, 3];
  public messages!: any;
  dropdownListCountries: any;
  dropdownListLanguages: any;
  dropdownListRegions: any;
  theFormGroup: FormGroup
  selectedRegions: any;
  selectedCountries: any;
  selectedLanguages: any;
  noNewRequests: any;
  noPhysicalResources: any;
  countryCount = 1
  regionCount = 1
  languageCount = 1
  dropdownSettings = {};
  public $countries: String[] = []
  public $languages: String[] = []
  public $regions: String[] = []
  constructor(
    private db: AngularFireDatabase,
    private route: ActivatedRoute,
    public router: Router,
    private builder: FormBuilder,
    public userService: UserService
  ) {    this.theFormGroup = this.builder.group({
    nameCheck: ['', []],
    projectManagersCheck: ['', []],
    capacityCheck: ['', []],
    priorityCheck: ['', []],
    countriesCheck: ['', []],
    rrCheck: ['', []]
  }); }

  ngOnInit() {
    this.populateDropdown()
    this.setInitialValidators()
    // this.nav()
  }
  setInitialValidators() {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValue(false)
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValue(false)
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValue(false)
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValue(false)
    }
    const rrCheck = this.theFormGroup.get('rrCheck');
    if (rrCheck) {
      rrCheck.setValue(false)
    }
  }
  submit(): void {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValidators(Validators.required);
      nameCheck.updateValueAndValidity();
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValidators(Validators.required);
      priorityCheck.updateValueAndValidity();
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValidators(Validators.required);
      projectManagersCheck.updateValueAndValidity();
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValidators(Validators.required);
      capacityCheck.updateValueAndValidity();
    }
    const rrCheck = this.theFormGroup.get('rrCheck');
    if (rrCheck) {
      rrCheck.setValidators(Validators.required);
      rrCheck.updateValueAndValidity();
    }
    if (this.selectedCountries != null) {
      this.countryCount = this.selectedCountries.length
    } else {
      this.countryCount = 0;
    }
    if (this.selectedLanguages != null) {
      this.languageCount = this.selectedLanguages.length
    } else {
      this.languageCount = 0;
    }
    if (this.theFormGroup.valid && this.countryCount > 0 && this.languageCount > 0) {
      this.sendToDataBase();
    }
  }
  getSubmissionCompanyInfo(): void {
    const check = this.route.snapshot.paramMap.get('id')
    this.id = check !== null ? check : '';
    this.db.object('/NPFP/Submitted/' + this.id).query.once('value')
      .then(snapshot => {
        console.log(snapshot.val())
        this.userId = snapshot.val().id
        this.name = snapshot.val().name
        this.projectManagers = snapshot.val().projectManagers
        this.capacity = snapshot.val().capacity
        this.messages = snapshot.val().messages
        this.date = snapshot.val().date
        const countries = snapshot.val().countries
        const regions = snapshot.val().regions
        const languages = snapshot.val().languages
        this.covidStatus = snapshot.val().covidStatus
        this.npfpEmail = snapshot.val().email
        if (countries != null) {
          for (let i = 0; i < countries.length; i++) {
            if (this.selectedCountries == null) {
              this.selectedCountries = ([{item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]}])
            } else {
              this.selectedCountries.push({item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]})
            }
          }
        }
        if (languages != null) {
          for (let i = 0; i < languages.length; i++) {
            if (this.selectedLanguages == null) {
              this.selectedLanguages = ([{item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]}]);
            } else {
              this.selectedLanguages.push({item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]});
            }
          }
        } else {

        }
        if (this.covidStatus != null) {
          this.noNewRequests = this.covidStatus.noNewRequests
          this.noPhysicalResources = this.covidStatus.noPhysicalResources
        }
        else {
          this.noNewRequests = false
          this.noPhysicalResources = false
        }
      }).then(() => this.getCompanyInfo());
  }
  sendToDataBase(): void {
    // company creation needs to be tied to account creation to tie together the node title and uid
    const name = (document.getElementById('name') as HTMLInputElement).value
    const capacity = Number((document.getElementById('capacity') as HTMLInputElement).value)
    const projectManagers = Number((document.getElementById('projectManagers') as HTMLInputElement).value)
    const countryArray: String[] = []
    let regionArray: String[] = []
    for(const element in this.selectedCountries) {
      countryArray.push(this.selectedCountries[element]['item_text']);
      regionArray.push(this.$regions[this.$countries.indexOf(this.selectedCountries[element]['item_text'])])
    }
    const languagesArray: String[] = []
    for (const element in this.selectedLanguages) {
      languagesArray.push(this.selectedLanguages[element]['item_text']);
    }
    regionArray = this.duplicateRemoval(regionArray)
    const covidStatus = {
      'noNewRequests': this.noNewRequests, 'noPhysicalResources': this.noPhysicalResources
    }
    // newId should be user Id
    const company = {'name': name, 'capacity': Number(capacity),
      'countries': countryArray, 'regions': regionArray, 'projectManagers': Number(projectManagers),
      'languages': languagesArray, 'covidStatus': covidStatus};
    const decidedCompany = {'currentName': name, 'capacity': Number(capacity), 'projectManagers': Number(projectManagers),
      'countries': countryArray, 'regions': regionArray,
      'languages': languagesArray, 'email': this.npfpEmail, 'decision': 'edited', 'messages': this.messages, 'date': this.date,
      'covidStatus': covidStatus};
    this.db.object('/NPFP/Companies/' + this.userId).update(company).then( () =>
      this.db.object('/NPFP/NPFP/' + this.userId).update(company).then(() => {
        this.db.object('/NPFP/Decided/' + this.id).update(decidedCompany).then( () => {
          this.db.object('/NPFP/Submitted/' + this.id).remove().then(() =>
            this.db.object('NPFP/Tickets/' + this.userId).update({'name': name}).then(() => {
                this.router.navigate(['/pending-items'])
              }
            )
          )
        })
      }))
  }
  populateDropdown(): void {

    this.db.object('/NPFP/Variables/Countries').query.once('value')
      .then(snapshot => {
        this.$countries = snapshot.val();
        this.dropdownListCountries = [];
        for (let i = 1; i < this.$countries.length; i ++) {
          this.dropdownListCountries.push({item_id: i, item_text: this.$countries[i]});
        }
      }).then(() => {this.db.object('/NPFP/Variables/Languages').query.once('value')
      .then(snapshot => {
        this.$languages = snapshot.val();
        this.dropdownListLanguages = [];
        for (let i = 0; i < this.$languages.length; i ++) {
          this.dropdownListLanguages.push({item_id: i, item_text: this.$languages[i]});
        }
      })}).then(() => {
      this.db.object('/NPFP/Mapping/countryRegionMap').query.once('value')
        .then(snapshot => {
          this.$regions = snapshot.val();
        }).then(() => this.getSubmissionCompanyInfo())
    })



    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }
  duplicateRemoval = function(a: any) {
    let seen!: any;
    seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1 && item !== undefined ) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }

  getCompanyInfo(): void {
    this.db.object('/NPFP/Companies/' + this.userId).query.once('value')
      .then(snapshot => {
        if (!this.name) {
          this.name = snapshot.val().name;
        }
        if (!this.projectManagers) {
          this.projectManagers = snapshot.val().projectManagers;
        }
        if (!this.capacity) {
          console.log("capacity")
          console.log(this.capacity)
          this.capacity = snapshot.val().capacity;
        }
        if (!this.selectedCountries) {
          const countries = snapshot.val().countries;
          if (countries != null) {
            for (let i = 0; i < countries.length; i++) {
              if (this.selectedCountries == null) {
                this.selectedCountries = ([{item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]}])
              } else {
                this.selectedCountries.push({item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]})
              }
            }
          }
        }
        if (!this.selectedLanguages) {
          const languages = snapshot.val().languages
          if (languages != null) {
            for (let i = 0; i < languages.length; i++) {
              if (this.selectedLanguages == null) {
                this.selectedLanguages = ([{item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]}]);
              } else {
                this.selectedLanguages.push({item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]});
              }
            }
          }
        }
        if (!this.covidStatus) {
          const covidStatus = snapshot.val().covidStatus
          this.noNewRequests = covidStatus.noNewRequests
          this.noPhysicalResources = covidStatus.noPhysicalResources
        }
      });
  }





}
