import { animate, state, style, transition, trigger } from '@angular/animations';
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import {Company} from '../shared/models';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';
import {AuthService} from '../shared/auth.service';
import {Router} from '@angular/router';
import {UserService} from '../shared/user.service';
import {MatSort, MatTableDataSource} from '@angular/material';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0', transform: 'scale(0)' }),
        animate('.25s ease-out', style({ opacity: '.6', transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: '.6' }),
        animate('.25s ease-out', style({ opacity: '0', transform: 'scale(0)' })),
      ]),
    ]),
    trigger('selected', [
      state('1', style({ transform: 'scale(1.1)', opacity: '1' })),
      transition('* <=> 1', [
        animate('.25s ease-in-out'),
      ]),
    ]),
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  private _$selectedCompanies = new BehaviorSubject<Company[]>([]);
  private requestedCompanies = new Array<Company>();
  private isAlive = true;
  private $tickets: any;
  public $filteredCompanies!: any;
  public searchControl = new FormControl();
  public filterText = '';
  public dataSource =  new MatTableDataSource();
  displayedColumns = ['name', 'capacityCalc', 'remainingCapacity', 'capacity', 'projectManagers', 'countries', 'regions', 'languages', 'covidStatus', 'status', 'actions'];
  @ViewChild(MatSort) sort!: MatSort;
  constructor(
    private db: AngularFireDatabase,
    public authService: AuthService,
    public router: Router,
    public userService: UserService,
  ) {
  }
  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((query: string) => this.filterText = query);
    this.initialize();
  }
  private initialize(): void {
    this.db.object('/NPFP/Tickets').query.once('value').then(ref => {
      this.$tickets = ref.val()
    }).then(() =>  {
        this.db.list(`/NPFP/Companies`).snapshotChanges().subscribe( ref => {
            this.$filteredCompanies = [];
            for (const element in ref) {
              let companyObject!: any;
              companyObject = ref[element]['payload'].val();
              if (this.$tickets  && companyObject) {
                companyObject['tickets'] = this.$tickets[companyObject['newId']]['tickets']
                companyObject['regions'] = companyObject['regions'].sort();
                companyObject['languages'] = companyObject['languages'].sort();
                companyObject['countries'] = companyObject['countries'].sort();
                if (companyObject['capacity'] !== 0) {
                  companyObject['capacityCalc'] = (this.$tickets[companyObject['newId']]['tickets']) / companyObject['capacity']
                  companyObject['remainingCapacity'] = companyObject['capacity'] - (this.$tickets[companyObject['newId']]['tickets'])
                } else {
                  companyObject['capacityCalc'] = 0
                  companyObject['remainingCapacity'] = 0
                }
                if (companyObject['covidStatus'] != null) {
                  let covidArray = []
                  if(companyObject['covidStatus'].noNewRequests === true)
                    covidArray.push('Cannot accept new projects')
                  if(companyObject['covidStatus'].noPhysicalResources === true)
                    covidArray.push('Unable to accept physical source materials')
                  companyObject['covidStatus'] = covidArray
                }
                else {
                  companyObject['covidStatus'] = []
                }
              }
              
              for (const column in companyObject) {
                if ((companyObject[column]).constructor === Array) {
                  companyObject[column] = (companyObject[column]).join(', ')
                }
              }
              this.$filteredCompanies.push(companyObject)
            }
            this.dataSource = new MatTableDataSource(this.$filteredCompanies);
            this.dataSource.sort = this.sort;
          }
        )
      }
    )
    this._$selectedCompanies.next([]);
    this.requestedCompanies = [];
  }
  ngOnDestroy(): void {
    this.isAlive = false;
  }
  public pullAllData(): void {
    this.saveAsXlsx(this.$filteredCompanies, 'Companies ')
  }
  private saveAsXlsx(json: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    const today = new Date();
    const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '_';
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const name = fileName + date + time;
    FileSaver.saveAs(data, name + EXCEL_EXTENSION);
  }
}

