import { Pipe, PipeTransform } from '@angular/core';
import { Company, Identity } from './models';

@Pipe({
    name: 'filterbyowner'
})
export class FilterByOwnerPipe implements PipeTransform {
    transform(filmStatuses: Array<Company>, filter: Identity): Array<Company> {
        if (filmStatuses && !!filter) {
            return filmStatuses.filter(b => b.name === filter.id);
        }
        return filmStatuses;
    }
}
