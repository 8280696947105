import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/Rx';
import {UserService} from '../shared/user.service';
import {Router} from '@angular/router';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-pending-items',
  templateUrl: './pending-items.component.html',
  styleUrls: ['./pending-items.component.scss']
})
export class PendingItemsComponent implements OnInit {
  public $startDropDownValue = 'Pending'
  public $submissions!: Observable<any[]>;
  public $showButtons = true;
  constructor( private db: AngularFireDatabase, public userService: UserService, public router: Router

  ) { }

  ngOnInit() {
    this.$submissions = this.db.list(`/NPFP/Submitted`).snapshotChanges()
  }

  public approve(company: any): void {
    const id = company.id;
    const subId = company.subId;
    const projectManagers = company.projectManagers;
    const capacity = company.capacity;
    const newCompany: {[id: string]: any} = {}
    if (capacity) {
      newCompany['capacity'] = capacity;
    }
    if (projectManagers) {
      newCompany['projectManagers'] = projectManagers;
    }
    const name = company.name;
    if (name) {
      newCompany['name'] = name;
    }
    const regions = company.regions;
    if (regions) {
      newCompany['regions'] = regions
    }
    const languages = company.languages;
    if (languages) {
      newCompany['languages'] = languages
    }
    const countries = company.countries;
    if (countries) {
      newCompany['countries'] = countries
    }
    const covidStatus = company.covidStatus;
    if (covidStatus) {
      newCompany['covidStatus'] = covidStatus
    }
    company['decision'] = 'accepted'
    this.db.object('NPFP/Companies/' + id).update(newCompany).then(() => {
      this.db.object('NPFP/NPFP/' + id).update(newCompany).then( () => {
        this.db.object('NPFP/Decided/' + subId).update(company).then(() => {
          if (name) {
            this.db.object('NPFP/Tickets/' + id).update({'name': name}).then( () => {
              this.db.object('NPFP/Submitted/' + subId).remove()
            })
          } else {
            this.db.object('NPFP/Submitted/' + subId).remove()
          }
        })}
        )})
  }
  callType(option: string) {
    if (option === 'Denied') {
      this.$showButtons = false;
      this.$submissions = this.db.list('/NPFP/Decided', ref =>
        ref.orderByChild('decision').equalTo('denied').limitToFirst(50)).snapshotChanges();
    } else if (option === 'Pending') {
      this.$showButtons = true;
      this.$submissions = this.db.list(`/NPFP/Submitted`).snapshotChanges()
    } else if (option === 'Approved') {
      this.$showButtons = false;
      this.$submissions = this.db.list('/NPFP/Decided', ref =>
        ref.orderByChild('decision').equalTo('accepted').limitToLast(50)).snapshotChanges();
    } else if (option === 'Edited') {
      this.$showButtons = false;
      this.$submissions = this.db.list('/NPFP/Decided', ref =>
        ref.orderByChild('decision').equalTo('edited').limitToFirst(50)).snapshotChanges( );
    }
  }
  public deny(company: any): void {
    const subId = company.subId;
    company['decision'] = 'denied'
        this.db.object('NPFP/Decided/' + subId).update(company).then(() => {
          this.db.object('NPFP/Submitted/' + subId).remove()
        }
      )
  }

  public pullAllData(): void {
    this.db.list('/NPFP/Decided', ).query.once('value').then(ref => {
      this.saveAsXlsx(ref.val(), 'Submission History ')
    })
  }

  private saveAsXlsx(json: any[], fileName: string): void {
    const dataArray = []
    for (const section in json) {
      for (const column in json[section]) {
        if ((json[section][column]).constructor === Array) {
          json[section][column] = (json[section][column]).join(', ')
        }
      }
      dataArray.push(json[section])
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataArray);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    const today = new Date();
    const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '_';
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const name = fileName + date + time;
    FileSaver.saveAs(data, name + EXCEL_EXTENSION);
  }

}
