import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase, AngularFireObject} from '@angular/fire/database';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../shared/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VALID} from '@angular/forms/src/model';
import {UserService} from '../shared/user.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public $company!: Company;
  public newId!: string;
  public priority: number[] = [1, 2, 3];
  dropdownListCountries: any;
  dropdownListLanguages: any;
  theFormGroup: FormGroup
  selectedCountries: any;
  selectedLanguages: any;
  noNewRequests: any;
  noPhysicalResources: any;
  countryCount = 1
  regionCount = 1
  languageCount = 1
  dropdownSettings = {};
  public $countries: String[] = []
  public $languages: String[] = []
  public $regions: String[] = []
  public $tickets: any;
  constructor(
    private db: AngularFireDatabase,
    private route: ActivatedRoute,
    public router: Router,
    private builder: FormBuilder,
    public userService: UserService
  ) {    this.theFormGroup = this.builder.group({
    nameCheck: ['', []],
    projectManagersCheck: ['', []],
    capacityCheck: ['', []],
    priorityCheck: ['', []],
    countriesCheck: ['', []],
    rrCheck: ['', []],
    onTimeCheck: ['', []],
    ticketsCheck: ['', []],
  }); }

  ngOnInit() {
    this.populateDropdown()
    this.setInitialValidators()
    // this.nav()
  }

  setInitialValidators() {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValue(false)
    }
    const ticketsCheck = this.theFormGroup.get('ticketsCheck');
    if (ticketsCheck) {
      ticketsCheck.setValue(false)
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValue(false)
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValue(false)
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValue(false)
    }
    const rrCheck = this.theFormGroup.get('rrCheck');
    if (rrCheck) {
      rrCheck.setValue(false)
    }
    const onTimeCheck = this.theFormGroup.get('onTimeCheck');
    if (onTimeCheck) {
      onTimeCheck.setValue(false)
    }
  }
  submit(): void {
    const nameCheck = this.theFormGroup.get('nameCheck');
    if (nameCheck) {
      nameCheck.setValidators(Validators.required);
      nameCheck.updateValueAndValidity();
    }
    const ticketsCheck = this.theFormGroup.get('ticketsCheck');
    if (ticketsCheck) {
      ticketsCheck.setValidators(Validators.required);
      ticketsCheck.updateValueAndValidity();
    }
    const priorityCheck = this.theFormGroup.get('priorityCheck');
    if (priorityCheck) {
      priorityCheck.setValidators(Validators.required);
      priorityCheck.updateValueAndValidity();
    }
    const projectManagersCheck = this.theFormGroup.get('projectManagersCheck');
    if (projectManagersCheck) {
      projectManagersCheck.setValidators(Validators.required);
      projectManagersCheck.updateValueAndValidity();
    }
    const capacityCheck = this.theFormGroup.get('capacityCheck');
    if (capacityCheck) {
      capacityCheck.setValidators(Validators.required);
      capacityCheck.updateValueAndValidity();
    }
    const rrCheck = this.theFormGroup.get('rrCheck');
    if (rrCheck) {
      rrCheck.setValidators(Validators.required);
      rrCheck.updateValueAndValidity();
    }
    const onTimeCheck = this.theFormGroup.get('onTimeCheck');
    if (onTimeCheck) {
      onTimeCheck.setValidators(Validators.required);
      onTimeCheck.updateValueAndValidity();
    }
    if (this.selectedCountries != null) {
      this.countryCount = this.selectedCountries.length
    } else {
      this.countryCount = 0;
    }
    if (this.selectedLanguages != null) {
      this.languageCount = this.selectedLanguages.length
    } else {
      this.languageCount = 0;
    }
    if (this.theFormGroup.valid && this.countryCount > 0 && this.languageCount > 0) {
      this.sendToDataBase();
    }
  }
  getCompanyInfo(): void {
    const check = this.route.snapshot.paramMap.get('id')
    this.newId = check !== null ? check : '';
    this.db.object('/NPFP/Tickets/' + this.newId).query.once('value').then(tickets => {
      this.$tickets = tickets.val()
      this.db.object('/NPFP/Companies/' + this.newId).query.once('value')
        .then(snapshot => {
          this.$company = snapshot.val()
          const countries = snapshot.val().countries
          const languages = snapshot.val().languages
          const covidStatus = snapshot.val().covidStatus
          if (countries != null) {
            for (let i = 0; i < countries.length; i++) {
              if (this.selectedCountries == null) {
                this.selectedCountries = ([{item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]}])
              } else {
                this.selectedCountries.push({item_id: (this.$countries.indexOf(countries[i])), item_text: countries[i]})
              }
            }
          }
          if (languages != null) {
            for (let i = 0; i < languages.length; i++) {
              if (this.selectedLanguages == null) {
                this.selectedLanguages = ([{item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]}]);
              } else {
                this.selectedLanguages.push({item_id: this.$languages.indexOf(languages[i]), item_text: languages[i]});
              }
            }
          }

          if(covidStatus != null) {
            this.noNewRequests = covidStatus.noNewRequests
            this.noPhysicalResources = covidStatus.noPhysicalResources
          }
          else {
            this.noNewRequests = false 
            this.noPhysicalResources = false
          }
        })});
  }
  sendToDataBase(): void {
    // company creation needs to be tied to account creation to tie together the node title and uid
    const name = (document.getElementById('name') as HTMLInputElement).value
    const projectManagers = Number((document.getElementById('projectManagers') as HTMLInputElement).value)
    const capacity = Number((document.getElementById('capacity') as HTMLInputElement).value)
    const tickets = Number((document.getElementById('tickets') as HTMLInputElement).value)
    const priority = Number((document.getElementById('priority') as HTMLInputElement).value)
    const rr = Number((document.getElementById('rr') as HTMLInputElement).value)
    const onTime = Number((document.getElementById('onTime') as HTMLInputElement).value)
    let regionArray: String[] = []
    const countryArray: String[] = []
    for (const element in this.selectedCountries) {
      countryArray.push(this.selectedCountries[element]['item_text']);
      regionArray.push(this.$regions[this.$countries.indexOf(this.selectedCountries[element]['item_text'])])
    }
    const languagesArray: String[] = []
    for (const element in this.selectedLanguages) {
      languagesArray.push(this.selectedLanguages[element]['item_text']);
    }
    regionArray = this.duplicateRemoval(regionArray)
    const covidStatus = {
      'noNewRequests': this.noNewRequests, 'noPhysicalResources': this.noPhysicalResources
    }
    // newId should be user Id
    const ticketsData = {'tickets': tickets, 'name': name}
    const npfp = {'name': name, 'capacity': Number(capacity), 'countries': countryArray, 'regions': regionArray,
      'languages': languagesArray, 'projectManagers': Number(projectManagers), 'covidStatus': covidStatus}
    const company = {'name': name, 'capacity': Number(capacity), 'priority': priority,
      'countries': countryArray, 'regions': regionArray, 'projectManagers': Number(projectManagers),
      'languages': languagesArray, 'rr': rr, onTime, 'covidStatus': covidStatus};
    this.db.object('/NPFP/NPFP/' + this.newId).update(npfp).then(() =>
      this.db.object('/NPFP/Companies/' + this.newId).update(company).then(() =>
        this.db.object('NPFP/Tickets/' + this.newId).update(ticketsData)).then(() =>
        this.router.navigate(['/master-table'])
      ))
  }
  populateDropdown(): void {

    this.db.object('/NPFP/Variables/Countries').query.once('value')
      .then(snapshot => {
        this.$countries = snapshot.val();
        this.dropdownListCountries = [];
        for (let i = 1; i < this.$countries.length; i ++) {
          this.dropdownListCountries.push({item_id: i, item_text: this.$countries[i]});
        }
      }).then(() => {this.db.object('/NPFP/Variables/Languages').query.once('value')
      .then(snapshot => {
        this.$languages = snapshot.val();
        this.dropdownListLanguages = [];
        for (let i = 0; i < this.$languages.length; i ++) {
          this.dropdownListLanguages.push({item_id: i, item_text: this.$languages[i]});
        }
      })}).then(() => {
      this.db.object('/NPFP/Mapping/countryRegionMap').query.once('value')
        .then(snapshot => {
          this.$regions = snapshot.val();
        }).then(() => this.getCompanyInfo())
    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

 

  toggleStatus(): void {
    const currentStatus = this.$company.status;
    this.db.object('/NPFP/Companies/' + this.newId).update({'status': !currentStatus})
      .then(() => this.router.navigate(['/master-table']));
  }
  delete(): void {
    this.db.object('/NPFP/NPFP/' + this.newId).remove()
      .then( () => {
        this.db.object('/NPFP/Companies/' + this.newId).remove().then(() =>
          this.db.object('NPFP/Tickets/' + this.newId).remove().then(() =>
            this.router.navigate(['/master-table'])))
      });
  }
  duplicateRemoval = function(a: any) {
    let seen!: any;
    seen = {};
    const out = [];
    const len = a.length;
    let j = 0;
    for (let i = 0; i < len; i++) {
      const item = a[i];
      if (seen[item] !== 1 && item !== undefined ) {
        seen[item] = 1;
        out[j++] = item;
      }
    }
    return out;
  }
}
