import { Pipe, PipeTransform } from '@angular/core';
import {Search} from './models';

@Pipe({
    name: 'orderbytitle'
})
export class OrderByDatePipe implements PipeTransform {
    transform(searches: Array<Search>, args: string): Array<Search> {
        if (searches) {
            searches.sort((a: Search, b: Search) => {
                if (!a && !b) {
                    return 0;
                } else if (!a && b) {
                    return -1;
                } else if (a && !b) {
                    return 1;
                }
                return a.date.localeCompare(b.date);
            });
        }
        return searches;
    }
}
