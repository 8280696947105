import {Component, OnInit, ViewChild} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable} from 'rxjs/Rx';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {UserService} from '../shared/user.service';
import {Router} from '@angular/router';
import {MatSort, MatTableDataSource} from '@angular/material';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  public $searches!: any;
  public $dateFilter!: string;
  public $startDropDownValue = 'Last 7 Days'
  displayedColumns = ['company', 'country', 'language', 'email', 'date'];
  public dataSource =  new MatTableDataSource();
  @ViewChild(MatSort) sort!: MatSort;


  constructor( private db: AngularFireDatabase,
               public userService: UserService,
               public router: Router
  ) {
    const today = new Date();
    const date = today.getFullYear() + '-' + (('0' + (today.getMonth() + 1)).slice(-2)) + '-' + ('0' + today.getDate()).slice(-2);
    this.$dateFilter = date;
  }

  ngOnInit() {
    this.subtractDays(7)
  }
  callType(option: string) {
    if (option === 'Today') {
      this.subtractDays(0)
    } else if (option === 'Last 7 Days') {
      this.subtractDays(7)
    } else if (option === 'Last 30 Days') {
      this.subtractDays(30)
    }
  }
  subtractDays(days: number): void {
    const today = new Date();
    const todayFormatted = today.getFullYear() + '-' + (('0' + (today.getMonth() + 1)).slice(-2)) + '-' + ('0' + today.getDate()).slice(-2);
    const daysAgo = new Date(today.setDate(today.getDate() - days));
    const date = daysAgo.getFullYear() + '-' + (('0' + (daysAgo.getMonth() + 1)).slice(-2)) + '-' + ('0' + daysAgo.getDate()).slice(-2);
    this.$dateFilter = date;
    this.db.list('/NPFP/SearchResults', ref => ref.orderByChild('date').
    startAt(this.$dateFilter).endAt(todayFormatted)).snapshotChanges().subscribe(ref => {
        this.$searches = [];
        let data_chunk: any
        for (const element in ref) {
          data_chunk = ref[element].payload.val()
          for (const column in data_chunk) {
            if ((data_chunk[column]).constructor === Array) {
              data_chunk[column] = (data_chunk[column]).join(', ')
            }
          }
          this.$searches.push(data_chunk)
        }
        this.dataSource = new MatTableDataSource(this.$searches);
        this.dataSource.sort = this.sort;
      }
    )
  }
  public pullAllData(): void {
    this.saveAsXlsx(this.$searches, 'Search History ')

  }

  private saveAsXlsx(json: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    const today = new Date();
    const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '_';
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const name = fileName + date + time;
    FileSaver.saveAs(data, name + EXCEL_EXTENSION);
  }

}
